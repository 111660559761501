import React from 'react';
//import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import './style.scss';

import { dateConverter } from './../../../selectors/date';

function MyModal({article, handleCloseModal, isModalOpen}) {

  let formattedDate = null;

  if (article.start_date) {
    formattedDate = article
    ? dateConverter(article.start_date)[0] + " " + dateConverter(article.start_date)[1] + " " + dateConverter(article.start_date)[2] + " " + dateConverter(article.start_date)[3]
    : "Date inconnue";
  }

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleString('fr-FR', {
      dateStyle: 'medium',
    });
  }; 
   
  return (
    <Modal show={isModalOpen} onHide={handleCloseModal} size="lg" >
      <Modal.Header closeButton>
        <Modal.Title>{article.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='mymodal__body'>
        <img src={article.image} className='mymodal__body__image' alt={article.title} />
        <p className='mymodal__body__content' dangerouslySetInnerHTML={{__html: article.description}}></p>
        { formattedDate  &&  <p> <b>Le { formattedDate }</b></p>}
        {article.address_label && <p>Lieu: <b>{article.address_label}</b></p>}
        {article.gallery.length > 0 ? 
          <>
            <p> Retour en images :</p>
            <div className='mymodal__body__gallery'>
              {article.gallery.map((image, index) => {
                return (
                    <img key={index} src={image.url} className='mymodal__body__image' alt={article.title} />
                )
              }
            )}
            </div>
          </>
          : null
        }
      </Modal.Body>
      <Modal.Footer className='firstNewsItem__container__footer'>
          <div className="text-muted h6 float-end">publié le {formatDate(article.published_at)} </div>
      </Modal.Footer>
    </Modal>
  );
}

MyModal.propTypes = {
};

export default MyModal;