import React, { useState } from 'react';
//import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import MyModal from './../MyModal';
import './style.scss';


function FirstNewsItem({article}) {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleReadClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleString('fr-FR', {
      dateStyle: 'medium',
    });
  };  

 
  return (
    <div className="firstNewsItem">
      <Card onClick={handleReadClick} className='firstNewsItem__container'>
        <Card.Img variant="top" src={article.image} alt={article.title} className='firstNewsItem__container__image' />
        <Card.Body>
          <Card.Title className='firstNewsItem__container__title'><b>{article.title}</b></Card.Title>
          <Card.Text className='firstNewsItem__container__content'>
            {article.description.length > 500 ?
               <span dangerouslySetInnerHTML={{__html: article.description.slice(0, 500)+"..."}}></span>
                :
                <span dangerouslySetInnerHTML={{__html: article.description}}></span>
            }
          </Card.Text>
        </Card.Body>
        <Card.Footer className='firstNewsItem__container__footer'>
          <div className="text-muted h6 float-end">publié le {formatDate(article.published_at)} </div>
        </Card.Footer>
      </Card>

      <MyModal article={article} handleCloseModal={handleCloseModal} isModalOpen={isModalOpen} />
    </div>
  );
}

FirstNewsItem.propTypes = {
};

export default FirstNewsItem;