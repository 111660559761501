import React, { useState } from 'react';
//import PropTypes from 'prop-types';
import MyModal from './../MyModal';
import './style.scss';


function AllNewsItems({article, articles}) {

  const lastArticle = articles[articles.length - 1];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleReadClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleString('fr-FR', {
      dateStyle: 'medium',
    });
  }; 

  return (
    
      <div className={article === lastArticle ? 'allNewsItem allNewsItem__last' : 'allNewsItem' }>
        <div onClick={handleReadClick}>
          <img src={article.image} alt={article.title} className='allNewsItem__image__image'/>
        </div>
        <h2 onClick={handleReadClick} className='allNewsItem__title'>{article.title} </h2>
        {article.description.length >= 190 ?
            <p onClick={handleReadClick} className='allNewsItem__description' dangerouslySetInnerHTML={{__html: article.description.slice(0, 190)+"..."}}></p>
            :
            <p onClick={handleReadClick} className='allNewsItem__description' dangerouslySetInnerHTML={{__html: article.description}}></p>
        }
        <div className='allNewsItem__footer'>
          <div className="text-muted h6 float-end"> publié le {formatDate(article.published_at)} </div>
        </div>
       <MyModal article={article} handleCloseModal={handleCloseModal} isModalOpen={isModalOpen} />
      </div>
  ); 
}

AllNewsItems.propTypes = {
};

export default AllNewsItems;